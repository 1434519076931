import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { useLocalStorage } from '../../services/hooks/useLocalStorage';

//import loadingIcon from '../../img/loading.gif';

const useStyles = makeStyles({
    loading: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: '35%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h1': {
            width: '100%',
            position: 'absolute',
            top: '160px',
            textAlign: 'center',
            fontSize: '1.5em',
            fontWeight: '400',
            margin: '0'
        },
        '& h2': {
            width: '100%',
            position: 'absolute',
            top: '185px',
            textAlign: 'center',
            fontSize: '1.0em',
            fontWeight: 'normal',
            margin: '8px 0'
        }
    },
    h1: {
        top: '80px !important'
    },
    h2: {
        top: '150px !important'
    },
    container: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: '0'
    },
    button: {
        position: 'absolute',
        top: '240px'
    },
    processing: {
        display: 'table',
        position: 'absolute',
        top: '10%',
        left: '50%',
        marginLeft: '-80px',
        marginTop: '-80px'
    },
    processingCenter: {
        background: 'rgb(255, 255, 255)',
        position: 'absolute',
        zIndex: '1002',
        top: '31px',
        right: '31px',
        height: '98px',
        width: '98px',
        borderRadius: '98px',
    },
    processingRow: {
        display: 'table-row',
        height: '20px'
    },
    processingOne: {
        border: '5px solid rgb(255,255,255)',
        borderTopLeftRadius: '160px',
        display: 'table-cell',
        height: '80px',
        width: '80px',
        boxSizing: 'border-box',
        background: 'linear-gradient(45deg, #b0ecfc 45%, rgba(0,0,0,0) 45%, rgba(0,0,0,0) 55%, #b0ecfc 55%)'
    },
    processingTwo: {
        border: '5px solid rgb(255,255,255)',
        borderTopRightRadius: '160px',
        display: 'table-cell',
        height: '80px',
        width: '80px',
        boxSizing: 'border-box',
        background: 'linear-gradient(-45deg, #b0ecfc 45%, rgba(0,0,0,0) 45%, rgba(0,0,0,0) 55%, #b0ecfc 55%)'
    },
    processingThree: {
        border: '5px solid rgb(255,255,255)',
        borderBottomLeftRadius: '160px',
        display: 'table-cell',
        height: '80px',
        width: '80px',
        boxSizing: 'border-box',
        background: 'linear-gradient(-45deg, #b0ecfc 45%, rgba(0,0,0,0) 45%, rgba(0,0,0,0) 55%, #b0ecfc 55%)'
    },
    processingFour: {
        border: '5px solid rgb(255,255,255)',
        borderBottomRightRadius: '160px',
        display: 'table-cell',
        height: '80px',
        width: '80px',
        boxSizing: 'border-box',
        background: 'linear-gradient(45deg, #b0ecfc 45%, rgba(0,0,0,0) 45%, rgba(0,0,0,0) 55%, #b0ecfc 55%)'
    },
    idle: {
        background: 'linear-gradient(45deg, #b0ecfc 45%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 55%,#b0ecfc 55%)'
    },
    idleAlt: {
        background: 'linear-gradient(-45deg, #b0ecfc 45%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 55%,#b0ecfc 55%)'
    },
    frameOne: {
        background: 'linear-gradient(45deg, #00baf2 45%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 55%,#b0ecfc 55%)'
    },
    frameTwo: {
        background: 'linear-gradient(45deg, #b0ecfc 45%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 55%,#00baf2 55%)'
    },
    frameThree: {
        background: 'linear-gradient(-45deg, #b0ecfc 45%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 55%,#00baf2 55%)'
    },
    frameFour: {
        background: 'linear-gradient(-45deg, #00baf2 45%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 55%,#b0ecfc 55%)'
    }

});

const show = (classes) => {
    const runme = () => {
        const p1 = document.getElementById('processing-1');
        const p2 = document.getElementById('processing-2');
        const p3 = document.getElementById('processing-3');
        const p4 = document.getElementById('processing-4');
        if (!p1 || !p2 || !p3 || !p4) {
            return;
        }
        const processingElements = [
            [p1, `${classes.processingOne} ${classes.idle}`],
            [p2, `${classes.processingTwo} ${classes.idleAlt}`],
            [p3, `${classes.processingThree} ${classes.idleAlt}`],
            [p4, `${classes.processingFour} ${classes.idle}`]
        ];
        const frames = [
            [p1, `${classes.processingOne} ${classes.frameOne}`],
            [p1, `${classes.processingOne} ${classes.frameTwo}`],
            [p2, `${classes.processingTwo} ${classes.frameThree}`],
            [p2, `${classes.processingTwo} ${classes.frameFour}`],
            [p4, `${classes.processingFour} ${classes.frameTwo}`],
            [p4, `${classes.processingFour} ${classes.frameOne}`],
            [p3, `${classes.processingThree} ${classes.frameFour}`],
            [p3, `${classes.processingThree} ${classes.frameThree}`]
        ]
        let active = 0;
        let count = 0;
        return () => {
            count += 1;
            if (count % 30 === 0 && window?.km_event?.ResetEvent) {
                window.km_event.ResetEvent();
            }
            const n = (active + 1) % frames.length;
            const f = frames[n];
            for (let i = 0; i < processingElements.length; i++) {
                processingElements[i][0].className = processingElements[i][1];
            }
            f[0].className = f[1];
            active = n;
        }
    }
    const f = runme();
    f();
    return setInterval(f, 1000);
}

const MFPLoading = (classes) => {
    return (
        <div id="processing" className={classes.processing}>
            <div id="processing-center" className={classes.processingCenter}></div>
            <div className={classes.processingRow}>
                <div id="processing-1" className={classes.processingOne}></div>
                <div id="processing-2" className={classes.processingTwo}></div>
            </div>
            <div className={classes.processingRow}>
                <div id="processing-3" className={classes.processingThree}></div>
                <div id="processing-4" className={classes.processingFour}></div>
            </div>
        </div>
    )
}

const Loading = (props) => {
    const classes = useStyles();
    const [stratus] = useLocalStorage("stratus", false);
    const ns = stratus ? 'stratus' : 'scantrip';
    const { t } = useTranslation(ns);
    const text = props.text || t('Loading...')
    let h1 = '';
    let h2 = '';
    if (props.hideIcon) {
        h1 = classes.h1;
        h2 = classes.h2;
    }
    useEffect(() => {
        let interval;
        if (!props.hideIcon) {
            interval = show(classes);
        }
        return function cleanup() {
            clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    return (
        <div className={classes.loading} >
            {props.hideIcon ? <></> : MFPLoading(classes)}
            <h1 className={h1}>{text}</h1>
            <h2 className={h2}>{props.subtext}</h2>
            <div className={classes.button}>{props.button}</div>
        </div>
    )
}

export default Loading;