import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { ReactComponent as PeopleIcon } from '../../img/shared.svg';

import { useWindowDimensions } from '../../services/hooks/useWindowDimensions';
import { getWfxPreviewImgUrl } from '../../services/api';

const WorkflowButton = (props) => {
    const tenant = props.tenant;
    const workflow = props.workflow;
    const visibility = workflow.placeholder ? 'hidden' : 'visible';
    const imgUrl = getWfxPreviewImgUrl(tenant.id, workflow.id, tenant.region);

    // This is to make the screen responsive on the MFPs. Media queries did not work
    const { width } = useWindowDimensions();

    let cardWidth = "200px";
    let cardHeight = "154px";
    let cardMargin = "8px";
    let textWidth = "168px";
    let picSide = "98px";
    if (width < 864) {
        cardWidth = "160px";
        cardHeight = "110px";
        cardMargin = "10px";
        textWidth = "128px";
        picSide = "60px";
    }
    if (width <= 800) {
        cardWidth = "142px";
    }

    const cardStyles = {
        width: cardWidth,
        maxHeight: cardHeight,
        margin: cardMargin,
        background: "#E6E6E6",
        boxShadow: "none",
        borderRadius: 0,
        justifyContent: "center",
        visibility: visibility,
    }

    // const divStyle = `position: relative; width: ${picSide}; height: ${picSide}`
    const divStyle = {
        position: 'relative',
        width: picSide,
        height: picSide
    }

    return (
        <>
            <Card sx={cardStyles} onClick={() => props.onClick(workflow.id)}>
                <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
                    <Typography sx={{ position: "absolute", top: "6px", right: "6px" }}>
                        {workflow.shared ? <PeopleIcon/> : <></>}
                    </Typography>
                    <div style={divStyle}>
                        <CardMedia
                            component="img"
                            // height="98"
                            image={imgUrl}
                            alt=""
                            sx={{
                                maxWidth: '100%',
                                maxHeight: picSide,
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 'auto',
                            }}
                        />
                    </div>
                    <Typography sx={{
                        fontSize: 14,
                        "textAlign": "center",
                        "textOverflow": "ellipsis",
                        overflow: "hidden",
                        "whiteSpace": "nowrap",
                        "maxWidth": textWidth,
                    }}>{workflow.name}</Typography>
                </CardContent>
            </Card>
        </>
    )
}

export default WorkflowButton