import { Button, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { useLocalStorage } from '../../services/hooks/useLocalStorage';

import Loading from '../Loading';

import STCLogo from '../../img/stc.png';
import StratusLogo from '../../img/stratus.png';

const useStyles = makeStyles({
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& *': {
            margin: '8px'
        }
    },
    empty_tenant_container: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        margin: "10%"
    },
    empty_tenant_text_container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    empty_tenant_header: {
        color: "#1b89b0",
        fontSize: "1em",
    },
    empty_tenant_text: {
        margin: "8px 0",
        textAlign: "center"
    }
});

const TenantSelector = (props) => {
    const [tenant, setTenant] = useState('');
    const [stratus] = useLocalStorage("stratus", false);
    const classes = useStyles();
    const ns = stratus ? 'stratus' : 'scantrip';
    const { t } = useTranslation(ns);

    const handleChange = (event) => {
        setTenant(event.target.value);
    }

    if (props.loading) {
        return <Loading />
    }


    if (props.tenants && props.tenants?.length > 0) {
        return (
            <div className={classes.box}>
                <InputLabel id="tenant-select-label">Select Tenant</InputLabel>
                <Select
                    labelId="tenant-select-label"
                    id="tenant-select"
                    value={tenant}
                    onChange={handleChange}
                    sx={{
                        width: '346px',
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        '& svg': { top: 9 }
                    }}
                >
                    {props?.tenants?.map(tenant => {
                        return <MenuItem value={tenant} key={tenant.slug}>{tenant.name}</MenuItem>
                    })}
                </Select>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={() => props.onClick(tenant)}
                    sx={{
                        width: "121px",
                        height: "42px",
                        margin: "32px"
                    }}
                    disabled={tenant === ''}
                >
                    {t('OK')}
                </Button>
            </div>
        );
    } else if (props.tenants) {
        const logo = stratus ? StratusLogo : STCLogo;
        return (
            <div className={classes.empty_tenant_container}>
                <div>
                    <img src={logo} alt="Dispatcher ScanTrip Cloud" />
                </div>
                <div className={classes.empty_tenant_text_container}>
                    <h2 className={classes.empty_tenant_header}>{t('No Tenant Found')}</h2>
                    <div className={classes.empty_tenant_text}>
                        {t('This MFP has not been assigned a Dispatcher ScanTrip Cloud license.')}
                    </div>
                    <div className={classes.empty_tenant_text}>
                        {t('To use this feature with this MFP, please refer to the documentation.')}
                    </div>
                </div>
            </div>
        );
    }
    return <Loading />
}

export default TenantSelector