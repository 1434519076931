import React, { useEffect } from 'react';
import { addCookieToMFPList } from '../../services/api';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';

const UserLogin = (props) => {
    const tenant = props.tenant;
    const [stratus] = useLocalStorage("stratus", false);

    useEffect(() => {
        async function handleLogin() {
            const callback = `${window.location.protocol}//${window.location.host}/auth`;  // "https://mfp.stratus.lol/auth";
            const state = window.btoa(
                unescape(encodeURIComponent(`${tenant?.userPoolId}|${tenant?.userPool?.Domain}|${tenant?.clientId}|${callback}|${tenant.region}|${tenant.id}|${tenant.name}|${tenant.plan}`))
            );
            const logoutUrl = `${window.location.protocol}//${window.location.host}/logout`;  // "https://mfp.stratus.lol/logout";
            const params = new URLSearchParams({
                "client_id": tenant.clientId,
                "response_type": "code",
                "redirect_uri": callback,
                "logout_uri": logoutUrl,
                "scope": "email openid profile",
                "state": state
            });
            const authURL = `${tenant.slug}.auth.${tenant.region}.amazoncognito.com`
            let appId = '0A024AC9';
            if (stratus) {
                appId = '0A024ADM';
            }
            await addCookieToMFPList(authURL, appId);
            window.location.replace(
                `https://${authURL}/login?${params.toString()}`
            );
        }
        handleLogin();
    // eslint-disable-next-line
    }, [tenant]);

    return (
        <div>

        </div>
    )
}

export default UserLogin