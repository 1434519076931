import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';
import RocketImg from '../../img/rocket.png';

const useStyles = makeStyles({
    resultContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '400px',
        '& *': {
            margin: '12px'
        }
    },
    resultText: {
        maxWidth: '600px',
        textAlign: 'center'
    },
});

const Success = () => {
    const classes = useStyles();
    const [stratus] = useLocalStorage("stratus", false);
    const ns = stratus ? 'stratus' : 'scantrip';
    const { t } = useTranslation(ns);

    return (
        <div className={classes.resultContainer}>
            <img src={RocketImg} alt="Success" />
            <div className={classes.resultText}>{t('Your document has been scanned, processed, and sent to the selected destination.')}</div>
        </div>
    )
}

export default Success;
