import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';
import Header from '../Header';
import Loading from '../Loading';

import {
    getMFPUserSettings
} from '../../services/api';

const NoDipa = () => {
    const history = useHistory();
    const location = useLocation();
    const [_noDipa, setNoDipa] = useLocalStorage("noDipa", false);
    const [_stratus, setStratus] = useLocalStorage("stratus", false);
    const [_appVersion, setAppVersion] = useLocalStorage('appVersion', '')
    // Used for clearing session
    const [_user, setUser] = useLocalStorage('user', '');
    const [_tenant, setTenant] = useLocalStorage('tenant', undefined);
    const [_accessToken, setAccessToken] = useLocalStorage('accessToken', undefined);
    const [_idToken, setIdToken] = useLocalStorage('idToken', undefined);
    const [_refreshToken, setRefreshToken] = useLocalStorage('refreshToken', undefined);
    const [_client, setClient] = useLocalStorage('client', undefined);
    const [_sqta, setSqta] = useLocalStorage('dipa-sqta', '');

    async function getSettings(stratus) {
        // TODO Remove settings from localStorage and use this function
        let appId = '0A024AC9';
        if (stratus) {
            appId = '0A024ADM';
        }
        const settings = await getMFPUserSettings(appId);
        if (settings?.userData?.refreshToken) {
            setRefreshToken(settings?.userData?.refreshToken);
        }
        if (settings?.userData?.client) {
            setClient(settings?.userData?.client);
        }
        if (settings?.userData?.tenant) {
            setTenant(settings?.userData?.tenant);
        }
        if (settings?.userData?.idToken) {
            setIdToken(settings?.userData?.idToken);
        }
        if (settings?.userData?.accessToken) {
            setAccessToken(settings?.userData?.accessToken);
        }
        if (settings?.userData?.user) {
            setUser(settings?.userData?.user);
        }
    }

	useEffect(() => {
        const sessionCheck = async () => {
            setNoDipa(true);
            const params = new URLSearchParams(location.search);
            const keepLoggedIn = params.get("keepLoggedIn");
            const isStratusPresent = params.get("stratus");
            if (isStratusPresent) {
                setStratus(true);
            } else {
                // If both STC and Stratus are installed, this will help switch between both of them
                setStratus(false);
            }
            const version = params.get("version");
            if (version) {
                setAppVersion(version)
            }
            if (!keepLoggedIn) {
                setTenant(undefined);
                setUser('');
                setAccessToken(undefined);
                setIdToken(undefined);
                setRefreshToken(undefined);
                setClient(undefined);
                setSqta('');
                history.push('/');
            } else {
                await getSettings(isStratusPresent);
                history.push('/');
            }
        }
        sessionCheck();
    // eslint-disable-next-line
	},[]);

    return (
        <>
            <Header />
            <Loading />
        </>
    )
}

export default NoDipa