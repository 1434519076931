import { useLocalStorage } from '../../services/hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';

import Loading from '../Loading';

const Split = () => {
    const [stratus] = useLocalStorage("stratus", false);
    const ns = stratus ? 'stratus' : 'scantrip';
    const { t } = useTranslation(ns);
    return (
        <Loading
            hideIcon={true}
            text={t('processing:iframe:split')}
        />
    );
}


export default Split