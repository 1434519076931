import { Grid, Typography } from '@mui/material';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';

import SuccessIcon from '../../img/submitted.svg';

const Background = () => {
    const [stratus] = useLocalStorage("stratus", false);
    const ns = stratus ? 'stratus' : 'scantrip';
    const { t } = useTranslation(ns);
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
            height="100vh"
        >
            <Grid item className="m-2">
                <img src={SuccessIcon} alt="success icon" />
            </Grid>
            <Grid item className="m-2">
                <Typography variant="h4">
                    {t('processing:iframe:background')}
                </Typography>
            </Grid>
        </Grid>
    );
}


export default Background