import { useLocation, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';

import { getWorkflowUI } from '../../services/api';
import { useInterval } from '../../services/hooks/useInterval';
import Header from '../Header';
import Loading from '../Loading';
import Success from '../Success';
import Failure from '../Failure';

const Processing = () => {
    const [noDipa] = useLocalStorage("noDipa");
    const [tenant, setTenant] = useLocalStorage('tenant', undefined);
    const [isProcessing, setIsProcessing] = useState(true);
    const [backgroundProcessing, setBackgroundProcessing] = useState(false);
    const [split, setSplit] = useState(false);
    const [delay, setDelay] = useState(0);
    const [stratus, setStratus] = useLocalStorage("stratus", false);
    const ns = stratus ? 'stratus' : 'scantrip';
    const [inIFrame, setInIFrame] = useState(false);
    const [iframeMessage, setIFrameMessage] = useState('');
    const [iframeSuccess, setIFrameSuccess] = useState(false);
    const [iframeDone, setIFrameDone] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation(ns);

    const isInIFrame = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const sendAuthRequest = () => {
        if (!window.top) {
            return;
        }
        window.top.postMessage({
                source: 'dp-cloud-device-portal',
                payload: { type: 'auth-request' }
        }, '*');
    }

    const sendNextUrlToSite = (url) => {
        if (!window.top) {
            return;
        }
        window.top.postMessage({
                source: 'dp-cloud-device-portal',
                payload: { type: 'next-url', url }
        }, '*');
    }

    const sendJobDone = (success) => {
        if (!window.top) {
            return;
        }
        const url = `${window.location.origin}/${success ? 'success' : 'failure'}`;
        window.top.postMessage({
                source: 'dp-cloud-device-portal',
                payload: { type: 'job-done', url }
        }, '*');
    }

    const listenForMessage = (event) => {
        console.log(event);
        if (event?.data?.payload?.tenant) {
            const messageTenant = event.data.payload.tenant ?? {};
            messageTenant.iframe = true;
            setTenant(messageTenant);
            const isPlanStratus = event.data.payload?.brand === 'stratus';
            isPlanStratus ? setStratus('stratus') : setStratus('scantrip');
        }
    }

    useInterval(async () => {
        if (split) {
            return;
        }
        if (isInIFrame()) {
            setInIFrame(true);
            sendAuthRequest();
        }
        const arn = location.state?.arn || new URLSearchParams(location.search).get("arn");
        if (!arn) {
            if (inIFrame) {
                setIFrameMessage(t('processing:no-arn'));
                sendJobDone(false);
                return;
            }
            history.push('/', { success: false });
        }
        try {
            if (!tenant?.region) {
                console.error('Missing tenant region!');
                return;
            }
            const response = await getWorkflowUI(arn, tenant.region);
            if (response && Object.keys(response).length === 0) {
                if (inIFrame) {
                    setIFrameMessage(t('processing:no-response'));
                    sendJobDone(false);
                    return;
                }
                history.push('/', { success: false });
            }
            if (response.background || response.split) {
                setBackgroundProcessing(true);
                if (response.split) {
                    setIFrameMessage(t('processing:iframe:split'));
                    const url = `${window.location.origin}/split`;
                    sendNextUrlToSite(url);
                    setSplit(true);
                } else {
                    setIFrameMessage(t('processing:iframe:background'));
                    const url = `${window.location.origin}/background`;
                    sendNextUrlToSite(url);
                }
            }
            if (!response.processing) {
                if (response.waitingForInput) {
                    setIsProcessing(false);
                    const params = new URLSearchParams({
                        arn: response.arn,
                        token: response.token,
                        url: response.callbackUrl,
                        workflowId: response.workflowId,
                        nodeId: response.nodeId,
                        metadata: response.metadata
                    });
                    const redirectUrl = `${response.url}?${params.toString()}`;
                    sendNextUrlToSite(redirectUrl);
                    window.location = redirectUrl;
                } else if (response.done && !response.split) {
                    if (inIFrame) {
                        setIFrameSuccess(response.success);
                        setIFrameDone(true);
                        setDelay(10000000);
                        sendJobDone(true);
                        return;
                    }
                    history.push('/', { success: response.success, });
                }
            }
        } catch (e) {
            console.error(e);
            if (inIFrame) {
                setIFrameMessage(t('processing:error'));
                sendJobDone(false);
                return;
            }
            history.push('/', { success: false });
        }
    }, delay);

    useEffect(() => {
        if (isProcessing) {
            setDelay(2000);
        } else {
            setDelay(0);
        }
    }, [isProcessing])

    useEffect(() => {
        window.addEventListener('message', listenForMessage);
        return () => {
            window.removeEventListener('message', listenForMessage);
        }
    // eslint-disable-next-line
    }, [])

    const processingDisplay = (text, subtext, buttonText) => {
        if (backgroundProcessing) {
            return (
                <Loading
                    hideIcon={!inIFrame}
                    text={text}
                    subtext={subtext}
                    button={buttonText ? (
                        <Button variant="contained" onClick={() => history.push('/')}>{buttonText}</Button>
                        ) : (<div></div>)}
                />
            );
        }
        return (
            <Loading />
        );
    }

    // iframe check isn't instantenous by tenant check should be. Hide header if tenant is undefined

    return (
        <>
            {inIFrame ? (
                iframeDone ? (
                    iframeSuccess ? (
                        <Success/>
                    ) : (
                        <Failure/>
                    )
                ) : (
                    <div>
                        {processingDisplay(iframeMessage)}
                    </div>
                )
            ) : (
                <div>
                    {tenant && !tenant.iframe && (<Header noDipa={noDipa}/>)}
                    <div>
                        {processingDisplay(t('Your scanned document is being processed by ScanTrip Cloud.'), t('You may wait to input any additional information required by the workflow or return to the home screen to continue using workflows.'), t('Home'))}
                    </div>
                </div>
            )}
        </>
    )
}

export default Processing