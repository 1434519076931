import jwtDecode from 'jwt-decode';
import semver from 'semver';

const WFX_URL = (process.env.REACT_APP_WFX_URL || "localhost:3000");
const MFP_ADDRESS = 'http://127.0.0.1:8090';
const URLS = {
    'DEV': {
        'us-east-1': 'https://2rkjc4p901.execute-api.us-east-1.amazonaws.com/dev',
        'eu-central-1': 'https://io59oayis1.execute-api.eu-central-1.amazonaws.com/dev',
        'ap-northeast-1': 'https://kt9ula9e5b.execute-api.ap-northeast-1.amazonaws.com/dev',
        'ca-central-1': 'https://ca-central-1.ntf.stratus.lol'
    },
    'STAGING': {
        'us-east-1': 'https://t09d4tj8oc.execute-api.us-east-1.amazonaws.com/beta',
        'eu-central-1': 'https://eu-central-1.ntf.dodgestrat.us',
        'ap-northeast-1': 'https://ap-northeast-1.ntf.dodgestrat.us',
        'ca-central-1': 'https://ca-central-1.ntf.dodgestrat.us'
    },
    'PROD': {
        'us-east-1': 'https://m41s6emkk9.execute-api.us-east-1.amazonaws.com/prod',
        'eu-central-1': 'https://eu-central-1.ntf.scantripcloud.com',
        'ap-northeast-1': 'https://ap-northeast-1.ntf.scantripcloud.com',
        'ca-central-1': 'https://ca-central-1.ntf.scantripcloud.com'
    }
}

const _sortAndFilter = (input) => {
    const list = input.reduce((filtered, workflow) => {
        if (workflow?.status === "running") {
            if (workflow?.acl?.users?.length > 1 || workflow?.acl?.groups?.length > 0) {
                workflow.shared = true;
            }
            filtered.push(workflow);
        }
        return filtered;
    }, []);
    list.sort((a, b) => a.name.localeCompare(b.name));

    return list;
}

export const getWorkflows = async (token, region, appId = '0A024AC9') => {
    if (typeof token !== 'string')
        throw new Error('token not a string')
        const hash = await getMFPHash(appId);

    const MOCKDATA = [
        {
            "id": "1778458c-7e1c-4503-a59e-7248058da725",
            "name": "Workflow 1",
            "acl": {
                "users": [
                    {
                        "name": "Josh",
                        "role": "owner"
                    },
                    {
                        "name": "User1",
                        "role": "view"
                    }
                ]
            },
            "status": "running"
        },
        {
            "id": "Workflow 2",
            "name": "Workflow 2",
            "status": "running"
        },
        {
            "id": "Workflow 3",
            "name": "Workflow 3",
            "acl": {
                "users": [
                    {
                        "name": "Josh",
                        "role": "owner"
                    }
                ],
                "groups": [
                    {
                        "name": "Users",
                        "role": "view"
                    }
                ]
            },
            "status": "running",
        },
        {
            "id": "Workflow 4",
            "name": "Workflow 4",
            "status": "running"
        },
        {
            "id": "Workflow 5",
            "name": "Workflow 5",
            "status": "running"
        },
        {
            "id": "Workflow 6",
            "name": "Workflow 6",
            "acl": {
                "users": [
                    {
                        "name": "Josh",
                        "role": "owner"
                    },
                    {
                        "name": "User1",
                        "role": "view"
                    }
                ],
                "groups": [
                    {
                        "name": "Users",
                        "role": "view"
                    }
                ]
            },
            "status": "running"
        },
        {
            "id": "Workflow 7",
            "name": "Workflow 7",
            "status": "running"
        },
        {
            "id": "Workflow 8",
            "name": "Workflow 8",
            "status": "running"
        },
        {
            "id": "Workflow 9",
            "name": "Workflow 9",
            "status": "running"
        },
        {
            "id": "Long Title",
            "name": "This is a workflow with a really long title hahahahahahaha",
            "status": "running"
        },
        {
            "id": "1b501a9b9d2e493baaeee3c440c355ad",
            "name": "Scan to Google Drive",
            "status": "running"
        },
        {
            "id": "Draft Workflow",
            "name": "DRAFT",
            "status": "draft"
        }
    ]
    const date = Date.now()
    try {
        const resp = await fetch(`https://${region}.${WFX_URL}/wfx?status=running&inputs=MfpCapture&date=${date}`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'X-Device-Hash': hash
            }
        });
        if (!resp.ok) {
            return { status: resp.status, workflows: [] };
        }
        const json = await resp.json();
        const workflows = _sortAndFilter(json);
        return { status: resp.status, workflows }
    } catch (e) {
        if (window.location.host === 'localhost:3000') {
            return Promise.resolve({ status: 200, workflows: _sortAndFilter(MOCKDATA) });
        }
        console.error(e);
        alert(`${e} :: ${date}`)
        return Promise.reject(e);
    }
}

export const getWorkflowConfig = (_id) => {
    const MOCKDATA = 'http://localhost:3000'
    return MOCKDATA
}

export const startWorkflow = async (workflowId, tenant, token, dipa, stratus) => {
    const url = URLS[process.env.REACT_APP_STAGE || 'DEV'][tenant.region];
    let appId = '0A024AC9';
    if (stratus) {
        appId = '0A024ADM';
    }
    const hash = await getMFPHash(appId);
    console.log(hash);
    const response = await fetch(`${url}/workflow/start`, {
        method: 'POST',
        body: JSON.stringify({
            workflowId,
            tenant,
            dipa,
            hash,
            metadata: {
                stratus
            }
        }),
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    if (!response.ok) {
        let json = {}
        try {
            json = await response.json();
        } catch (e) {
            console.error(e);
        }
        return { 'error': response.statusText, 'status': response.status, body: json }
    }
    const json = await response.json();
    return json;
}

export const getWorkflowUI = async (arn, region) => {
    try {
        const url = URLS[process.env.REACT_APP_STAGE || 'DEV'][region]
        const response = await fetch(`${url}/workflow/ui`, {
            method: 'POST',
            body: JSON.stringify({
                arn
            })
        })
        const json = await response.json();
        return json;
    } catch (e) {
        throw (e);
    }
}

export const reportEnd = async (token, success, url) => {
    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            token,
            success
        })
    });
    return response;
}

export const getWfxPreviewImgUrl = (tenantId, workflowId, region) => {
    return `https://${region}.${WFX_URL}/wfx/${workflowId}/preview/mfp.png?t=${tenantId}`;
}

const getMFPHash = async (appId = '0A024AC9') => {
    // TODO Memoize this
    let debug = {}
    try {
        debug.hashRequest = true
        const resp = await sendMFPRequest(`${MFP_ADDRESS}/${appId}/getSerialHash.py`);
        debug.hashResponse = resp.status;
        const json = await resp.json()
        return json.hash;
    } catch (e) {
        console.error(e);
        debug.error = e;
        let s = ''
        for (const key in debug) {
        	s += `${key}:${debug[key]},`
        }
        // alert(s);
        console.log(s);
        const debugHash = JSON.parse(localStorage.getItem('debugHash'));
        const debugHashOverride = JSON.parse(localStorage.getItem('debugHashOverride'));
        if ((process.env.REACT_APP_STAGE === 'DEV' || debugHashOverride) && debugHash) {
            return debugHash;
        }
        return '';
    }
}

export const getMFPSettings = async (appId = '0A024AC9') => {
    try {
        const resp = await fetch(`${MFP_ADDRESS}/${appId}/getMFPSettings.py`);
        const json = await resp.json();
        return json;
    } catch (e) {
        console.error(e);
        if (process.env.REACT_APP_STAGE === 'DEV') {
            return {}
        }
        return {}
    }
}

export const getTenants = async (appId = '0A024AC9') => {
    const MOCKDATA = [
        {"id":"T:11111111-1111-1111-1111-111111111111","name":"SEC","slug":"sec","region":"us-east-1", "plan": "stc"},
        {"id":"T:11111111-1111-1111-1111-111111111112","name":"Don't Pick This One","slug":"Blarg","region":"us-east-1", "plan": "stc"},
        {"id":"T:11111111-1111-1111-1111-111111111113","name":"Stratus1","slug":"Stratus","region":"us-east-1","plan":"stratus"},
        {"id":"T:11111111-1111-1111-1111-111111111114","name":"Stratus2","slug":"Stratus2","region":"us-east-1","plan":"stratus"},
        {"id": "T:a67d94ba-69fa-5348-9a2d-220e02019d2c","name": "james-01-test","slug": "james-01-test","region": "us-east-1","expirationDate": 1726547096000,"plan": "stratus"}
    ]
    // return MOCKDATA
    // return []
    let debug = {};
    try {
        const hash = await getMFPHash(appId);
        debug.hash = hash;
        if (hash) {
            const host = window.location.host;
            const parts = host.split('.');
            let baseHost = parts[parts.length - 2] + '.' + parts[parts.length - 1];
            if (window.location.host === 'localhost:3000') {
                baseHost = 'stratus.lol';
            }
            debug.firstRequest = true;
            const resp = await fetch(`https://${baseHost}/api/device/tenants/${hash}`);
            debug.firstResponse = resp.status;
            const json = await resp.json();
            return json.map(item => {
                let id = item.id;
                if (id.indexOf("T:") > -1) {
                    id = id.substring(id.indexOf("T:")+2);
                }
                return {...item, id}
            });
        }
        return [];
    } catch (e) {
        if (window.location.host === 'mfp.stratus.lol') {
            console.error(e);
            // Used for debugging at the MFP
            let s = ''
            for (const key in debug) {
            	s += `${key}:${debug[key]},`
            }
            // alert(s);
            console.log(s);
            return Promise.reject(e);
        }
        if (window.location.host === 'localhost:3000') {
            const MOCKTENANTS = MOCKDATA.map(item => {
                let id = item.id;
                if (id.indexOf("T:") > -1) {
                    id = id.substring(id.indexOf("T:")+2);
                }
                return {...item, id}
            });
            return Promise.resolve(MOCKTENANTS);
        }
        console.error(e);
        return Promise.reject(e);
    }
}

const sendMFPRequest = async (url) => {
    let retry = 10;
    const loopMFPRequest = async (url) => {
        let response = await _sendMFPRequest(url);
        while (response.status === 500 || response.status === 503 || (response.status === 0 && retry > 0)) {
            if (response.status === 0) {
                retry -= 1;
            }
            // Wait a second before trying again
            await new Promise(resolve => setTimeout(resolve, 1000));
            response = await _sendMFPRequest(url);
        }
        return response;
    }
    const _sendMFPRequest = async (url) => {
        try {
            return await fetch(url);
        } catch (e) {
            console.log(e);
            return { status: 0 }
        }
    }
    const response = await loopMFPRequest(url);
    return response;
}

export const getToken = async (domain, region, code, client_id, client_secret, redirect_uri, grant_type='authorization_code') => {
    const formData = {
        code,
        client_id,
        client_secret,
        grant_type,
        redirect_uri
    };
    const params = new URLSearchParams(formData);
    const body = params.toString();
    const tokenResp = await fetch(`https://${domain}.auth.${region}.amazoncognito.com/oauth2/token`, {
        method: 'post',
        body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return await tokenResp.json();
}

export const revokeToken = async (domain, refresh_token, client_id, client_secret, region='us-east-1') => {
    const formData = {
        token: refresh_token,
        client_id
    }
    const params = new URLSearchParams(formData);
    const body = params.toString();

    return await fetch(`https://${domain}.auth.${region}.amazoncognito.com/oauth2/revoke`, {
        method: 'post',
        body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${btoa(`${client_id}:${client_secret}`)}`
        }
    }).catch(err => {
        console.error('revokeToken:', err);
    });
}

export const refreshToken = async (domain, refresh_token, client_id, client_secret, region='us-east-1') => {
    const formData = {
        client_id,
        refresh_token,
        grant_type: 'refresh_token'
    }
    const params = new URLSearchParams(formData);
    const body = params.toString();
    const tokenResp = await fetch(`https://${domain}.auth.${region}.amazoncognito.com/oauth2/token`, {
        method: 'post',
        body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${btoa(`${client_id}:${client_secret}`)}`
        }
    });
    return await tokenResp.json();
}

export const getUserPoolClient = async (poolId, clientId, region) => {
    const url = URLS[process.env.REACT_APP_STAGE || 'DEV'][region];
    const response = await fetch(`${url}/userpool/client`, {
        method: 'post',
        body: JSON.stringify({
            poolId,
            clientId,
            region
        })
    });
    return await response.json();
}
export const getUserPoolId = async (_domain, _region) => {

}

export const getUserPoolClientId = async (_poolId, _region) => {

}

export const getUserPool = async (_poolId, _region) => {

}

export const getUserPoolAll = async (slug, region) => {
    const url = URLS[process.env.REACT_APP_STAGE || 'DEV'][region];
    const response = await fetch(`${url}/userpool/all`, {
        method: 'post',
        body: JSON.stringify({
            slug,
            region
        })
    });
    return await response.json();
}

export const getUserDataFromToken = async (accessToken, slug, region) => {
    const url = URLS[process.env.REACT_APP_STAGE || 'DEV'][region];
    const response = await fetch(`${url}/userpool/user`, {
        method: 'post',
        body: JSON.stringify({
            accessToken,
            slug,
            region,
        })
    });
    return await response.json();
}

export const lookupTenantFromIssuer = async(issuer) => {
    const url = WFX_URL;
    const region = issuer.slice(0, issuer.indexOf('_'));
    const response = await fetch(`https://${region}.${url}/tenants/${issuer}`);
    return await response.json();
}

export const getTokenExpiration = (token) => {
    try {
        const now = Math.floor(Date.now() / 1000);
        const decodedToken = jwtDecode(token);

        if (!decodedToken)
            return -1;

        const ret = decodedToken.exp - now;
        return ret;
    } catch (e) {
        console.error(e);
    }

    return -1;
};

export const hasTokenExpired = (token, zero) => {
    zero = typeof zero !== "undefined" ? zero : 0;

    try {
        const exp = getTokenExpiration(token);
        return exp <= zero;
    } catch (e) {
        console.error(e);
    }
};

export const getMFPUserSettings = async (appId = '0A024AC9') => {
    try {
        const resp = await fetch(`${MFP_ADDRESS}/${appId}/getMFPSettings.py`);
        const json = await resp.json();
        return json;
    } catch (e) {
        console.error(e);
        if (process.env.REACT_APP_STAGE === 'DEV') {
            // Used for returning custom responses during development
            return {}
            
        }
        return {}
    }
}

export const setMFPUserSettings = async (settings, appId = '0A024AC9') => {
    try {
        const resp = await fetch(`${MFP_ADDRESS}/${appId}/getMFPSettings.py`, {
            method: 'post',
            body: JSON.stringify(settings)
        });
        const json = await resp.json();
        return json;
    } catch (e) {
        console.error(e);
        if (process.env.REACT_APP_STAGE === 'DEV') {
            return {}
        }
        return {}
    }
}

export const clearMFPUserSettings = async (appId = '0A024AC9') => {
    return await setMFPUserSettings({ clear: true }, appId)
}

export const addCookieToMFPList = async (cookie, appId = '0A024AC9') => {
    try {
        const resp = await fetch(`${MFP_ADDRESS}/${appId}/cookies.py?cookies=${encodeURIComponent(cookie)}`);
        return resp.ok;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const getCookieDeletionList = async (appId) => {
    try {
        const resp = await fetch(`${MFP_ADDRESS}/${appId}/cookies.json`);
        if (!resp.ok) {
            return [];
        }
        const json = await resp.json();
        if (json?.cookies) {
            return json.cookies;
        }
        return [];
    } catch (e) {
        console.error(e);
        return [];
    }
}

export const restartApp = async (appId) => {
    window.location = `${MFP_ADDRESS}/${appId}`;
    return new Promise(resolve => {
        setTimeout(() => resolve(() => console.log('Waited for app redirect')), 3000)
    })
}

export const isVersionValid = (version) => {
    if (!semver.valid(version)) {
        return false
    }
    return semver.valid(version) && semver.gt(version, '2.5.0');
}
